import React from 'react'

import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import AssistantIcon from '@mui/icons-material/Assistant';

import { QueryClient, QueryClientProvider, useQuery } from 'react-query'


import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const queryClient = new QueryClient()


export default function Home() {
    return (
        <>

            <ImageView />

            <NotificationScroller />

            <BottomOptions />



        </>
    )
}



function ImageView() {
    return (
        <Carousel className='m-1 border border-warning border-2 p-2'>
            <Carousel.Item>
                <img src="https://hptu-plus.pages.dev/api/banner-images/0.jpg" className="d-block w-100" alt="..." height="200px" />
            </Carousel.Item>
            <Carousel.Item>
                <img src="https://hptu-plus.pages.dev/api/banner-images/1.jpg" className="d-block w-100" alt="..." height="200px" />
            </Carousel.Item>
            <Carousel.Item>
                <img src="https://hptu-plus.pages.dev/api/banner-images/2.jpg" className="d-block w-100" alt="..." height="200px" />
            </Carousel.Item>
        </Carousel>
    )

}

function NotificationScroller() {
    return (
        <>
            <h1 className='text-center mt-3 bg-orange text-white p-2'>Notifications</h1>

            <div className='border border-primary border-2 rounded p-2'>
                <marquee height='250px' direction="up" onmouseover="this.stop()" onmouseout="this.start()" scrollamount="2" scrolldelay="50">

                    <QueryClientProvider client={queryClient}>
                        <LatestEvents />
                    </QueryClientProvider>


                </marquee>
            </div>
        </>
    )
}

function BottomOptions() {
    return (
        <>

            <div class="row mt-2">


                <Link class="col-4 btn text-primary" to="/hptu-courses" >
                    <div class="p-3 border rounded bg-white shadow text-center">
                        <StarBorderOutlinedIcon />
                        <br />
                        <b>HPTU COURSES</b>
                    </div>
                </Link>


                <Link class="col-4 btn text-primary" to="/hptu-photo-gallery">
                    <div class="p-3 border rounded bg-white shadow text-center">
                        <PhotoLibraryOutlinedIcon />
                        <br />
                        <b>
                            PHOTO GALLERY
                        </b>
                    </div>
                </Link>


                <Link class="col-4 btn text-primary" to='/hptu-affilated-colleges' >
                    <div class="p-3 border rounded bg-white shadow text-center">
                        <Diversity3OutlinedIcon />
                        <br />
                        <b>Affilate Colleges</b>
                    </div>
                </Link>

            </div>

            <div class="row mt-2">

            <a class="col-4 btn text-primary" onClick={()=>{window.Android.openWebContent("https://www.himtu.ac.in/contact-us/")}} >
                    <div class="py-3 border rounded bg-white shadow text-center">
                        <AssistantIcon />
                        <br />
                        <b>Contact Details</b>
                    </div>
                </a>

                <a class="col-4 btn text-primary" onClick={()=>{window.Android.openWebContent("https://docs.google.com/forms/d/e/1FAIpQLSeEnKq5Yxg5WCmiTgsFmZ8oz348MdpOSh5J9rQBwNK-wFih0w/viewform?usp=sf_link")}} >
                    <div class="py-3 border rounded bg-white shadow text-center">
                        <AssistantIcon />
                        <br />
                        <b>SUGGESTION</b>
                    </div>
                </a>

                <a class="col-4 btn text-primary" onClick={()=>{window.Android.openWebContent("https://hptu-plus-disclaimer.pages.dev/")}} >
                    <div class="py-3 border rounded bg-white shadow text-center">
                        <AssistantIcon />
                        <br />
                        <b>DISCLAIMER</b>
                    </div>
                </a>

            </div>

        </>
    )
}



function LatestEvents() {
    const { isLoading, error, data } = useQuery('repoData', () =>
        fetch(`https://hptu-plus.pages.dev/api/events.json`).then(res =>
            res.json()
        )
    )

    if (isLoading) return 'Loading...'

    if (error) return 'An error has occurred: ' + error.message


    return (
        <>

            {data.map((event) => (
                <>
                    <a className='text-break text-black' onClick={() => { OpenType(event) }}>
                        <b className='text-danger'> {event.date}</b> <i>{event.title}</i>
                    </a>
                    <br />
                    <br />
                </>


            ))}

        </>
    )
}

function OpenType(props) {
    if (props.type == "web") {
        window.Android.openWebContent(props.url)
    } else {
        window.Android.openPDF(props.url)
    }
}