import React from 'react'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { LazyLoadImage } from "react-lazy-load-image-component";

const queryClient = new QueryClient()




export default function PhotoGallery() {
  return (
    <div>



      <QueryClientProvider client={queryClient}>
        <GalleryPhotos />
      </QueryClientProvider>


    </div>


  )
}


function GalleryPhotos() {
  const { isLoading, error, data } = useQuery('repoData', () =>
    fetch("https://hptu-plus.pages.dev/api/photo-gallery.json").then(res =>
      res.json()
    )
  )

  if (isLoading) return 'Loading...'

  if (error) return 'An error has occurred: ' + error.message


  return (
    <>


      {data.mainData.map((photo) => (
        <div className='text-center'>
          <LazyLoadImage className='mb-2' src={photo.src} width="360px" loading='lazy' />
        </div>
      ))}



      

    </>
  )
}