import React from 'react'





export default function AffilatedColleges() {

  return (
    <div>

      <AffilatedCollegesList />


    </div>
  )
}


function AffilatedCollegesList() {
  return (
    <>
      <table className='table table-striped'>
        <thead className='bg-orange text-white text-center'>
          <tr>
            <th className='col'>Sr.No</th>
            <th className='col'>Course</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className='col'>1.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/list-of-affiliated-bachelor-of-architecture-colleges/")}}>
                Bachelor of Architecture
              </a></th>
          </tr>

          <tr>
            <th className='col'>2.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/affiliated-colleges-for-bachelor-of-hotel-management-and-catering-technology/")}}>
                Bachelor of Hotel Management and Catering Technology
              </a>
            </th>
          </tr>

          <tr>
            <th className='col'>3.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/list-of-affiliated-b-pharmacy-colleges/")}}>
                Bachelor of Pharmacy
              </a>
            </th>
          </tr>
          <tr>
            <th className='col'>4.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/affiliated-colleges-for-b-sc-in-hotel-management-and-catering-technology-b-sc-hmct/")}}>
                B.Sc in Hotel Management and Catering Technology
              </a>
            </th>
          </tr>
          <tr>
            <th className='col'>5.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/list-of-affiliated-b-tech-engineering-colleges/")}}>
                Bachelor of Technology
              </a>
            </th>
          </tr>
          <tr>
            <th className='col'>6.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/affiliated-colleges-for-mba-in-tourism-and-hospitality-management-mba-thm/")}}>
                MBA(Tourism and Hospitality Management)
              </a>
            </th>
          </tr>
          <tr>
            <th className='col'>7.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/list-of-master-of-environmental-science-colleges/")}}>
                M.Sc in Environmental Sciences
              </a>
            </th>
          </tr>
          <tr>
            <th className='col'>8.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/affiliated-institutes/list-of-affiliated-colleges-for-master-of-physics")}}>
                M.Sc Physics
              </a>
            </th>
          </tr>
          <tr>
            <th className='col'>9.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/affiliated-institutes/list-of-affiliated-colleges-for-m-pharmacy")}}>
                Master of Pharmacy
              </a>
            </th>
          </tr>
          <tr>
            <th className='col'>10.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/list-of-affiliated-colleges-for-master-of-technology/")}}>
                Master of Technology
              </a>
            </th>
          </tr>
          <tr>
            <th className='col'>11.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/list-of-affiliated-management-colleges/")}}>
                Master of Business Administration
              </a>
            </th>
          </tr>
          <tr>
            <th className='col'>12.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/list-of-affiliated-colleges-for-mca/")}}>
                Master of Computer Applications
              </a>
            </th>
          </tr>
          <tr>
            <th className='col'>13.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/list-of-affiliated-colleges-for-bachelor-of-business-administration/")}}>
                Bachelor of Business Administration
              </a>
            </th>
          </tr>
          <tr>
            <th className='col'>14.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/list-of-affiliated-colleges-for-bca/")}}>
                Bachelor of Computer Applications
              </a>
            </th>
          </tr>
          <tr>
            <th className='col'>15.</th>
            <th className='col'>
              <a className='' onClick={()=>{openWebContent("https://www.himtu.ac.in/affiliated-institutes/list-of-affiliated-colleges-for-pg-diploma-in-yoga")}}>
                PG Diploma in Yoga
              </a>
            </th>
          </tr>

        </tbody>
      </table>
    </>
  )
}


function openWebContent(url){
  if(url!=null){
    window.Android.openWebContent(url);
  }
}




