import React from 'react'

export default function HptuCourse() {
  return (
    <>
      <table className='table table-striped p-3'>
        <thead className='bg-orange text-white text-center'>
          <tr>
            <th className='col'>Sr.No</th>
            <th className='col'>Course</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className='col'>1.</th>
            <th className='col'>Bachelor of Architecture</th>
          </tr>

          <tr>
            <th className='col'>2.</th>
            <th className='col'>Bachelor of Hotel Management and Catering Technology</th>
          </tr>

          <tr>
            <th className='col'>3.</th>
            <th className='col'>Bachelor of Pharmacy</th>
          </tr>
          <tr>
            <th className='col'>4.</th>
            <th className='col'>B.Sc in Hotel Management and Catering Technology</th>
          </tr>
          <tr>
            <th className='col'>5.</th>
            <th className='col'>Bachelor of Technology</th>
          </tr>
          <tr>
            <th className='col'>6.</th>
            <th className='col'>MBA(Tourism and Hospitality Management)</th>
          </tr>
          <tr>
            <th className='col'>7.</th>
            <th className='col'> 	M.Sc in Environmental Sciences</th>
          </tr>
          <tr>
            <th className='col'>8.</th>
            <th className='col'>M.Sc Physics</th>
          </tr>
          <tr>
            <th className='col'>9.</th>
            <th className='col'>Master of Pharmacy</th>
          </tr>
          <tr>
            <th className='col'>10.</th>
            <th className='col'>Master of Technology</th>
          </tr>
          <tr>
            <th className='col'>11.</th>
            <th className='col'>Master of Business Administration</th>
          </tr>
          <tr>
            <th className='col'>12.</th>
            <th className='col'>Master of Computer Applications</th>
          </tr>
          <tr>
            <th className='col'>13.</th>
            <th className='col'>Bachelor of Business Administration</th>
          </tr>
          <tr>
            <th className='col'>14.</th>
            <th className='col'>Bachelor of Computer Applications</th>
          </tr>
          <tr>
            <th className='col'>15.</th>
            <th className='col'>PG Diploma in Yoga</th>
          </tr>

        </tbody>
      </table>
    </>
  )
}
