import './App.css';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PhotoGallery from './pages/PhotoGallery';
import HptuCourse from './pages/HptuCourse';
import AffilatedColleges from './pages/AffilatedColleges'
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <div className='container'>
    <BrowserRouter>
    <Routes>


      <Route path='/' element={<Home />} />
      <Route path='/hptu-courses' element={<HptuCourse />} /> 
      <Route path='/hptu-photo-gallery' element={<PhotoGallery />} /> 
      <Route path='/hptu-affilated-colleges' element={<AffilatedColleges />} /> 
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      
      
    </Routes>
    
    </BrowserRouter>
    
    </div>
  );
}

export default App;
